<script>
export default {
  props: {
    name: String,
    attr: Object,
    checked: String,
    value: [Boolean,Number],
    validate: {default:""},
    disabled: Boolean,
    isOption:Boolean,
  },
  data(){
    return {
      input: this.value,
    }
  },
  methods:{
    onClick(){
      if(this.isOption){
        var text = (this.input==1?'inactive':'active')
        return swal({
          title: "Are you sure to "+text+" subscribe?",
          text:"",
          icon: "warning",
          buttons: ["No, Cancel!", "Yes, Sure!"],
          dangerMode: false,
        }).then((ok) => {
          if (ok) {
            this.$emit('input', !this.input)
            return swal("Updated!", "Subscribe was successful active.", "success");
          }
        });
      }
      this.$emit('input', !this.input)
      setTimeout(()=>$(this.$el).find("input").valid(), 10)
    }
  },
  watch:{
    input(v){ this.$emit("input", v); },
    value(v){ this.input = v; },
  }
}
</script>

<template>
  <div class="custom-control custom-checkbox" @click="onClick">
    <input class="custom-control-input" id="staticCheck" type="checkbox" v-model="input" :name="name" v-bind="attr">
    <label class="custom-control-label"><slot></slot></label>
  </div>
</template>